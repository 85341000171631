
import { Vue, Component } from 'vue-property-decorator'

@Component
export default class DeviceDetail extends Vue {
  private activeName = 'base'
  config = ''
  deviceNumber = ''
  customer = ''

  get typeId () {
    return this.$route.query.typeid as string
  }

  created () {
    this.activeName = this.$route.path.substr(this.$route.path.lastIndexOf('/') + 1)
    this.config = this.$route.query.config as string
    this.deviceNumber = this.$route.query.number as string
    this.customer = this.$route.query.customer as string
  }

  handleClick () {
    const path = `/deviceList/deviceDetail/${this.$route.params.deviceId}/${this.activeName}?typeid=${this.typeId}&config=${this.config}&number=${this.deviceNumber}&customer=${this.customer}`
    if (path !== this.$route.path) {
      this.$router.replace({ path: path })
    }
  }
}
